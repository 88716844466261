<template>
    <div class="w-full p-12 mx-12 my-auto">
        <div class="mb-6 text-center">
            <h1>Almost there!</h1>
            <p class="text-gray-500">
                Fill out the form below to set your password and finalize your account.
            </p>
        </div>
    
        <form @submit.prevent="reset" @keydown="form.onKeydown($event)">
            <alert v-if="status" color="green">
                {{ status }}
            </alert>
    
            <!-- Email -->
            <div class="mt-4 form-group row">
                <label class="col-md-3 col-form-label text-md-right">{{ $t('email') }}</label>
                <div class="col-md-7">
                    <input v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }" class="form-control" type="email" name="email" placeholder="your@email.com">
                    <has-error :form="form" field="email" />
                </div>
            </div>
    
    
            <!-- Password -->
            <div class="form-group row">
                <label class="col-md-3 col-form-label text-md-right">{{ $t('password') }}</label>
                <div class="col-md-7">
                    <input v-model="form.password" :class="{ 'is-invalid': form.errors.has('password') }" class="form-control" type="password" name="password">
                    <has-error :form="form" field="password" />
                </div>
            </div>
    
            <!-- Password Confirmation -->
            <div class="form-group row">
                <label class="col-md-3 col-form-label text-md-right">{{ $t('confirm_password') }}</label>
                <div class="col-md-7">
                    <input v-model="form.password_confirmation" :class="{ 'is-invalid': form.errors.has('password_confirmation') }" class="form-control" type="password" name="password_confirmation">
                    <has-error :form="form" field="password_confirmation" />
                </div>
            </div>
    
            <!-- Submit Button -->
            <div class="form-group row">
                <div class="col-md-9 ml-md-auto">
                    <button class="w-full btn">
                    Save password
                  </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Form from 'vform'

export default {
    layout: 'login',

    metaInfo() {
        return { title: 'Almost there!' }
    },

    data: () => ({
        status: '',
        form: new Form({
            token: '',
            email: '',
            password: '',
            password_confirmation: '',
            type: 'welcome'
        })
    }),

    created() {
        this.form.email = this.$route.query.email
        this.form.token = this.$route.params.token
    },

    methods: {
        async reset() {
            const { data } = await this.form.post(this.$apiUrl.password.reset)

            this.status = data.status

            this.form.reset()

            setTimeout(() => {
              this.$router.push({
                name : 'login'
              })
            },1000)
        }
    }
}
</script>
